import React from "react"

import * as styles from "./VisitVenueLeaderList.module.scss"

const VisitVenueLeaderList = ({children}) => (

    <div className={styles.VisitVenueLeaderList}>
        {children}
    </div>

)

export default VisitVenueLeaderList