import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getVisitPagePreview as PREVIEW_QUERY } from "../preview-queries/visitPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import VisitVenueLeader from "../components/Visit/VisitVenueLeader"
import VisitVenueLeaderList from "../components/Visit/VisitVenueLeaderList"
import VisitAroundDerbyLeader from "../components/Visit/VisitAroundDerbyLeader"
import VisitAroundDerbyLeaderList from "../components/Visit/VisitAroundDerbyLeaderList"
import SectionTitle from "../components/Shared/SectionTitle"
import HeroAreaLarge from "../components/Shared/HeroAreaLarge"
import SectionBlurb from "../components/Shared/SectionBlurb"
import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const TemplateVisit = (props) => {
    const page = getContent(props, "page")
    const venue = venueForPage({ page: page, menus: props.data.menus })

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaLarge title={page.title} introduction={page.page_introduction.introduction} image={page.image.image} />

            <Main>
                <VisitVenueLeaderList>
                    {page.visit_fields.venues && page.visit_fields.venues.map((venue) => <VisitVenueLeader key={venue.id} {...venue} />)}
                </VisitVenueLeaderList>

                <SectionTitle>{page.visit_fields.title}</SectionTitle>

                <SectionBlurb>
                    <p dangerouslySetInnerHTML={{ __html: page.visit_fields.description }}></p>
                </SectionBlurb>

                <VisitAroundDerbyLeaderList>
                    {page.visit_fields.activities &&
                        page.visit_fields.activities.map((leader, index) => <VisitAroundDerbyLeader key={`leader-${index}`} {...leader} />)}
                    <div className="discoverDerbyWidget">
                        <iframe src="https://www.visitderby.co.uk/widget?theme=derbymuseum" style={{ border: 0, margin: 0 }} width="100%" height="530" />
                    </div>
                </VisitAroundDerbyLeaderList>
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query VisitPageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            visit_fields {
                venues {
                    ...VenueContent
                }
                title: derbyActivitiesTitle
                description: derbyActivitiesIntro
                activities: derbyActivities {
                    title
                    link
                    description
                    buttonText
                    image {
                        sourceUrl
                        title
                    }
                }
            }
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(TemplateVisit)
