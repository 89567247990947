import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { detect } from "detect-browser"

// Styles
import * as styles from "./VisitVenueLeader.module.scss"

// Components
import PlaceholderImage from "../Shared/PlaceHolderImage"
import Image from "../Shared/Image"
import { imageSizes } from "../../helpers/imgix"

const textContentVariants = {
    visible: {
        opacity: 1,
        x: 0,
        transition: { delay: 0.5, duration: 0.3, ease: "easeOut" },
    },
    hidden: { opacity: 0, x: 50 },
}

// Unset initial transition state for IE
const isIeBrowser = /(ie)/i.test(detect().name)
if (isIeBrowser) {
    textContentVariants.hidden.opacity = 1
    textContentVariants.hidden.x = 0
}

const VisitVenueLeader = (props) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    return (
        <div className={styles.VisitVenueLeader}>
            <div className={styles.logo} style={{ backgroundColor: props.venue_fields.logoBackgroundColour }}>
                <Image url={props.venue_fields.logo.sourceUrl} sizes={imageSizes.logo} />
            </div>

            {props.image && (
                <div className={styles.image}>
                    {props.image.image && <Image url={props.image.image.sourceUrl} alt={props.image.image.title} sizes={imageSizes.hero} />}
                </div>
            )}
            <motion.div className={styles.textContent} ref={ref} initial={typeof(window) !== 'object' ? 'visible' : 'hidden'} animate={controls} variants={textContentVariants}>
                <h3>{props.title}</h3>
                <div className={styles.description}>
                    <h3>Opening Times</h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: props.venue_fields.addressAndOpeningTimes.openingTimes,
                        }}
                    ></p>
                    <h3>Address</h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: props.venue_fields.addressAndOpeningTimes.address,
                        }}
                    ></p>
                </div>
                <div className={styles.link}>
                    {props.venue_fields.venueDetailsLink.link && (
                        <a href={props.venue_fields.venueDetailsLink.link.url}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: props.venue_fields.venueDetailsLink.linkText,
                                }}
                            ></p>
                        </a>
                    )}
                </div>
            </motion.div>
        </div>
    )
}

export default VisitVenueLeader
