import React from "react"

import * as styles from "./VisitAroundDerbyLeaderList.module.scss"

const VisitAroundDerbyLeaderList = ({children}) => (

    <div className={styles.VisitAroundDerbyLeaderList}>
        {children}
    </div>

)

export default VisitAroundDerbyLeaderList