import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { detect } from "detect-browser"

// Styles
import * as styles from "./VisitAroundDerbyLeader.module.scss"

// Components
import PlaceholderImage from "../Shared/PlaceHolderImage"
import Image from "../Shared/Image"
import { imageSizes } from "../../helpers/imgix"

const BoxVariants = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, ease: "easeOut", when: "beforeChildren" },
    },
    hidden: { opacity: 0, y: 80 },
}

const textContentVariants = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.3, ease: "easeOut" } },
    hidden: { opacity: 0, x: 50 },
}

// Unset initial transition state for IE
const isIeBrowser = /(ie)/i.test(detect().name)
if (isIeBrowser) {
    BoxVariants.hidden.opacity = 1
    BoxVariants.hidden.y = 0
    textContentVariants.hidden.opacity = 1
    textContentVariants.hidden.x = 0
}

const VisitAroundDerbyLeader = (props) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    return (
        <motion.div
            className={styles.VisitAroundDerbyLeader}
            ref={ref}
            animate={controls}
            initial={typeof window !== "object" ? "visible" : "hidden"}
            variants={BoxVariants}
        >
            <div className={styles.image}>{props.image && <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero} />}</div>
            <div className={styles.textContent}>
                <h3>{props.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
                <a href={props.link}>{props.buttonText}</a>
            </div>
        </motion.div>
    )
}

export default VisitAroundDerbyLeader
