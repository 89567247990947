import gql from "graphql-tag"

export const getVisitPagePreview = gql`
    query getVisitPagePreview($id: ID!, $revision_id: Int!) {
        page(id: $id, idType: DATABASE_ID) {
            title
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        link
                        content
                        image: hero_fields {
                            image {
                                sourceUrl
                                title
                            }
                        }
                        page_introduction {
                            introduction
                        }
                        visit_fields {
                            venues {
                                ... on Venue {
                                    id
                                    venueId: databaseId
                                    title
                                    link
                                    excerpt
                                    slug
                                    venue_fields {
                                        venueDetailsLink {
                                            linkText
                                            link {
                                                target
                                                title
                                                url
                                            }
                                        }
                                        logo {
                                            sourceUrl
                                            title
                                        }
                                        addressAndOpeningTimes {
                                            openingTimes
                                            address
                                            image {
                                                sourceUrl
                                                title
                                            }
                                            download {
                                                url: mediaItemUrl
                                            }
                                            downloadButtonText
                                        }
                                    }
                                    image: hero_fields {
                                        image {
                                            sourceUrl
                                            title
                                        }
                                    }
                                }
                            }
                            title: derbyActivitiesTitle
                            description: derbyActivitiesIntro
                            activities: derbyActivities {
                                title
                                link
                                description
                                buttonText
                                image {
                                    sourceUrl
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
